<template>
  <div class="wrap">
    <div class="top-banner">
      <div class="w-1200">
        <div class="title">全栈数据运营平台</div>
        <div class="en">DU-FDO</div>
        <p>一体化、一站式处理所有数据类开发以及运营工作</p>
      </div>
    </div>
    <div class="main-box w-1200">
      <div class="main-item">
        <div class="title">功能介绍</div>
        <div class="content">
          <div class="function-box">
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-1.png"/>
              <label>全链路元数据闭环管理</label>
              <p>数据源管理、系统管理、项目管理、元数据采集、元数据版本管理，元数据血缘分析、影响分析、关联度分析等。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-2.png"/>
              <label>全方位数据安全管理</label>
              <p>数据分级分类管理、脱敏规则管理、已授权资产管理、特权资产管理、日志审计等企业级功能；</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-3.png"/>
              <label>全场景数据交换</label>
              <p>包含数据采集、数据卸载、数据补录入等功能；数据交换对象包含数据文件、流数据库</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-4.png"/>
              <label>企业级数据资产管理</label>
              <p>包含表资产、服务资产、报表资产、外部数据资产等，统一管理，统一鉴权。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-5.png"/>
              <label>一站式数据开发</label>
              <p>具备实时数据采集、批量数据采集、数据ETL逻辑开发、数据卸载、报表开发、指标开发、AI模型开发与管理，作业调度自动配置等。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-6.png"/>
              <label>多终端智能分析</label>
              <p>在线数据探索、jupyterhub、Python/R、Dbeaver等客户端。</p>
            </div>
						<div class="function-item">
							<img src="~@/assets/images/product/function-icon-7.png"/>
							<label>系统监控</label>
							<p>包含调度监控、调度管理、系统监控等功能。</p>
						</div>
          </div>
        </div>
      </div>
      <div class="main-item">
        <div class="title">产品架构</div>
        <div class="content">
          <img src="~@/assets/images/fdo-architecture.png"/>
        </div>
      </div>
      <div class="main-item">
        <div class="title">核心优势</div>
        <div class="content">
          <div class="core-box">
						<div class="core-item">
							<img src="~@/assets/images/product/core-icon-1.png"/>
							<div class="text-box">
								<label>产品高度集成</label>
								<p>全栈数据运营平台，集成数据领域所有功能，以最佳实践规范与约束，提高数据运营效率</p>
							</div>
						</div>
						<div class="core-item">
							<img src="~@/assets/images/product/core-icon-2.png"/>
							<div class="text-box">
								<label>产品支持线性扩展</label>
								<p>产品基于SpringBoot，可根据需要快速部署到任意数量的节点上，对操作系统，硬件无特殊要求，也不需要统一</p>
							</div>
						</div>
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-3.png"/>
              <div class="text-box">
                <label>全场景覆盖</label>
                <p>100%覆盖数据处理与运营场景</p>
              </div>
            </div>
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-4.png"/>
              <div class="text-box">
                <label>降本增效</label>
                <p>统一平台，统一部署，管理与运维成本低；系统化方式，减少手工操作，减少学习成本</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="main-item">
        <div class="title">应用场景</div>
        <div class="content">
          <div class="scenario-box">
            <div class="scenario-item">
              <label>数据分析，面向企业精细化运营</label>
              <p>汇聚处理各类业务数据，基于中台强大的引擎能力完成复杂的分析与监控工作，灵活响应并满足新需求，实现对用户千人千面的精细化运营</p>
              <img src="~@/assets/images/rdc/scenario-1.png"/>
            </div>
            <div class="scenario-item">
              <label>数据治理，为企业数据保驾护航</label>
              <p>针对各种来源的数据，统一数据标准、统一数据资产，同时保障数据安全，从组织和个人角度，促进数据在组织内无障碍地进行交流</p>
              <img src="~@/assets/images/rdc/scenario-2.png"/>
            </div>
            <div class="scenario-item">
              <label>数据应用，助力企业数字化转型</label>
              <p>分析、管理数据，基于硬件和业务数据，完成企业的事前风险预测、事中监控调度和事后问题排查工作，保障生产和供应的可靠性</p>
              <img src="~@/assets/images/rdc/scenario-1.png"/>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>
.wrap {
  background-color: #F7F8FA;
}
.top-banner {
  height: 320px;
  background-image: url('~@/assets/images/product/banner.png');
  background-repeat: no-repeat;
  background-position: center;
  .title {
    font-size: 40px;
    font-weight: 100;
    color: #FFFFFF;
    margin-bottom: 20px;
    padding-top: 82px;
  }
  .en {
    font-size: 40px;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 20px;
  }
  p {
    font-size: 18px;
    font-weight: 100;
    color: #FFFFFF;
    opacity: 0.8;
    margin-bottom: 14px;
  }
}
.main-box {
  .title {
    font-size: 20px;
    font-weight: 500;
    color: #4F5969;
    padding: 40px 0;
  }
  .content {
    img {
      width: 1199px;
    }
  }
  .function-box {
    overflow: hidden;
    .function-item {
      box-sizing: border-box;
      float: left;
      width: 285px;
      height: 244px;
      background: #FFFFFF;
      border-radius: 5px;
      padding: 30px;
      margin-right: 19px;
      >img {
        width: 26px;
      }
      &:nth-child(-n+4) {
        margin-bottom: 19px;
      }
      &:nth-child(4n){
        margin-right: 0;
      }
      label {
        display: block;
        margin-top: 20px;
        font-size: 24px;
        font-weight: 400;
        color: #1D2129;
      }
      p {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #87909C;
        line-height: 1.2;
      }
    }
  }
  .core-box {
    overflow: hidden;
    .core-item {
      padding: 40px 28px 40px 30px;
      float: left;
      box-sizing: border-box;
      width: 590px;
      height: 159px;
      background: #FFFFFF;
      border-radius: 5px;
			margin-bottom: 20px;
      >img {
        width: 72px;
      }
      &:nth-child(2n-1) {
        margin-right: 20px;
      }
      img {
        float: left;
      }
      .text-box {
        margin-left: 102px;
        label {
          font-size: 24px;
          font-weight: 400;
          color: #1D2129;
        }
        p {
          margin-top: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #87909C;
          line-height: 1.2;
        }
      }
    }
  }
  .scenario-box {
    overflow: hidden;
    margin-bottom: 60px;
    .scenario-item {
      padding-top: 30px;
      float: left;
      width: 386px;
      background: #FFFFFF;
      border-radius: 5px;
      >img {
        width: 386px;
      }
      &:not(:last-child){
        margin-right: 19px;
      }
      label {
        font-size: 20px;
        font-weight: 400;
        color: #1D2129;
        padding: 30px 30px 0;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: #87909C;
        margin-top: 22px;
        margin-bottom: 30px;
        padding: 0 30px;
        height: 77px;
        line-height: 1.2;
      }
    }
  }
}
</style>
